import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    FORGET_PASSWORD_REQUEST,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAIL,
    NEW_PASSWORD_REQUEST,
    NEW_PASSWORD_SUCCESS,
    NEW_PASSWORD_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    CLEAR_ERROR,
    ADMIN_USERS_REQUEST,
    ADMIN_USERS_SUCCESS, 
    ADMIN_USERS_FAIL,
    NEW_USER_REQUEST,
    NEW_USER_SUCCESS,
    NEW_USER_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DEACTIVATE_USER_REQUEST,
    DEACTIVATE_USER_SUCCESS,
    DEACTIVATE_USER_FAIL,
    REACTIVATE_USER_REQUEST,
    REACTIVATE_USER_SUCCESS,
    REACTIVATE_USER_FAIL
} from '../constants/userConstants'

import axios from 'axios';


export const login = (email, password) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/login', { email, password }, config)

        dispatch({
            type: LOGIN_SUCCESS,
            payload: data.user
        })



    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.data.message
        })
    }
}

export const register = (userData) => async (dispatch) => {
    try {

        dispatch({ type: REGISTER_USER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/register', userData, config)

        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: data.user
        })



    } catch (error) {
        dispatch({
            type: REGISTER_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const loadUser = () => async (dispatch) => {
    try {

        dispatch({ type: LOAD_USER_REQUEST })


        const { data } = await axios.get('/api/v1/profile', {
            timeout: 30000
          })

        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data.user
        })



    } catch (error) {
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const allUsers = () => async (dispatch) => {
    try {

        dispatch({ type: ADMIN_USERS_REQUEST })


        const { data } = await axios.get('/api/v1/admin/users')

        dispatch({
            type: ADMIN_USERS_SUCCESS,
            payload: data.users
        })



    } catch (error) {
        dispatch({
            type: ADMIN_USERS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const newUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_USER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/admin/user/new', userData, config)

        dispatch({
            type: NEW_USER_SUCCESS,
            payload: data
        })



    } catch (error) {
        dispatch({
            type: NEW_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateUser = (id, userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_USER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/user/${id}`, userData, config)

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get user details - ADMIN
export const getUserDetails = (id) => async (dispatch) => {
    try {

        dispatch({ type: USER_DETAILS_REQUEST })


        const { data } = await axios.get(`/api/v1/admin/user/${id}`)

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data.user
        })

    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteUser = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_USER_REQUEST })


        const { data } = await axios.delete(`/api/v1/admin/user/${id}`)

        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: data.success
        })



    } catch (error) {
        dispatch({
            type: DELETE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deactivateUser = (id) => async (dispatch) => {
    try {

        dispatch({ type: DEACTIVATE_USER_REQUEST })


        const { data } = await axios.post(`/api/v1/admin/user/deactivate/${id}`)

        dispatch({
            type: DEACTIVATE_USER_SUCCESS,
            payload: data.success
        })



    } catch (error) {
        dispatch({
            type: DEACTIVATE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const reactivateUser = (id) => async (dispatch) => {
    try {

        dispatch({ type: REACTIVATE_USER_REQUEST })


        const { data } = await axios.post(`/api/v1/admin/user/reactivate/${id}`)

        dispatch({
            type: REACTIVATE_USER_SUCCESS,
            payload: data.success
        })



    } catch (error) {
        dispatch({
            type: REACTIVATE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}


export const updatePassword = (passwords) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_PASSWORD_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }


        const { data } = await axios.put('/api/v1/password/update', passwords, config)

        dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
            payload: data.success
        })



    } catch (error) {
        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error.response.data.message
        })
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {

        dispatch({ type: FORGET_PASSWORD_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }


        const { data } = await axios.post('/api/v1/password/forgot', email, config)

        dispatch({
            type: FORGET_PASSWORD_SUCCESS,
            payload: data.message
        })



    } catch (error) {
        dispatch({
            type: FORGET_PASSWORD_FAIL,
            payload: error.response.data.message
        })
    }
}

export const resetPassword = (token, passwords) => async (dispatch) => {
    try {

        dispatch({ type: NEW_PASSWORD_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }


        const { data } = await axios.put(`/api/v1/password/reset/${token}`, passwords, config)

        dispatch({
            type: NEW_PASSWORD_SUCCESS,
            payload: data.success
        })



    } catch (error) {
        dispatch({
            type: NEW_PASSWORD_FAIL,
            payload: error.response.data.message
        })
    }
}

export const logout = () => async (dispatch) => {
    try {

        const { data } = await axios.get('/api/v1/logout')

        dispatch({
            type: LOGOUT_SUCCESS,
            payload: data.success
        })



    } catch (error) {
        dispatch({
            type: LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR })
}
